
import './help.css';
import Navbar from '../Navbar/Navbar';
import botmodules from './cogs.json';
import allcommands from './commands.json';
import { useState } from 'react';
const Help = () => {
  const [cmds, setCmds] = useState(allcommands.commands)

  async function showCommands(mod) {
    if (mod === 'all') {
      await setCmds(allcommands.commands)
    } else {
      await setCmds(allcommands.commands.filter(command => command.category.toLocaleLowerCase() === mod));
    }
  }
  return (
    <>
      <Navbar />
      <hr/>
      <div className="main_help">
        <div className="sidebar">
          <div className="tab-container">
            {botmodules.cogs.map(cog => {
              return (<button className="tab" onClick={(e) => { e.preventDefault(); showCommands(cog.name.toLocaleLowerCase()); }}>{cog.name}</button>)
            })}
          </div>
        </div>
        <div className='commands-container'>
          <div className="category active" style={{ display: 'block' }}>
            {
              cmds.map(c => {
                return (
                  <>
                    <div className="command-box">
                      <h3>{c.name}</h3>
                      <p>{c.description}</p>
                      <p>{c.category}</p>
                      <hr />
                      <p>Usage:{" "}{c.usage}</p>
                    </div>
                  </>
                )
              })
            }
          </div>

        </div>

      </div >
    </>

  )
}
export default Help;
