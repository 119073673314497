
import './Navbar.css'
import { Link } from 'react-router-dom'



const Navbar = () => {
  return (

    <nav className="aware-navbar">
      <div className="aware-navbar-container">
        <Link className="aware-navbar-name" to="/home" >
          <img src="/aware.jpg" alt="" width="30" height="30" className="navbar-image" />
          Aware
        </Link>
      </div>
    </nav>


  )
}

export default Navbar;
