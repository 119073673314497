import './App.css';
import Navbar from './Components/Navbar/Navbar'
import Home from './Components/Home/Home';
import Information from './Components/Information/Information';
import {
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import Help from './Components/Help/Help';

function App() {
  return (
    <>
      <Switch>

        <Route exact path='/invite' component={() => {
          window.location.href = "https://discord.com/api/oauth2/authorize?client_id=1106094873951621120&permissions=8&scope=applications.commands%20bot";
        }} />
        <Route exact path='/support'  component={() => {
          window.location.href = "https://discord.gg/aware";
        }}/>
        <Route exact path='/help' component={Help}></Route>
        <Route path='/home'>
          <div className="mainapp">
            <Navbar />
            <Home />
            <Information />
          </div>
        </Route>
        <Route path='/' ><Redirect to='/home' /></Route>
      </Switch>

    </>
  );
}

export default App;
