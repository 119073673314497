
import './Home.css';
import { Link } from 'react-router-dom';

const Home = () => {

    return (

        <>
            <div className="home_section" id="home">
                <div className="gimage">
                    <img src="/aware.jpg" alt="awarelogo" />
                </div>
                <div className="mainintro">
                    <span className="stroketext_intro">Aware</span>
                    <span>the only discord bot you will ever need.</span>
                    <div className="linkbuttons">
                        <Link to='/invite'><button type="button" className="btn btn-outline-secondary" >Invite Me</button></Link>
                        <Link to='/support'><button type="button" className="btn btn-outline-secondary" >Support Server</button></Link>
                        <Link to='/help'><button type="button" className="btn btn-outline-secondary" >Commands</button></Link>
                    </div>
                </div>

            </div>
        </>

    )
}

export default Home;
