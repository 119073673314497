
import './Information.css'

const Information = () => {

  return (

    <>
      <div className="about_section" id="information">
        <div className="about_container">
          <div className="about_card">
            <h1>
              a<span className='about_heading'>bou</span>t Aw<span className='about_heading'>are</span>
            </h1>

            <p>Aware ....</p>
            <p>
            Aware is your all-in-one solution for enhancing your Discord server. With robust antinuke and antiraid features, it ensures the security and integrity of your community. Engage your members with exciting giveaways, allowing you to effortlessly organize and distribute rewards.
            </p>

          </div>
        </div>
      </div>
    </>

  )
}

export default Information;
